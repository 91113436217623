import React, {FC, useEffect, useState} from 'react';
import {useMutation} from '@apollo/react-hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faDownload} from '@fortawesome/free-solid-svg-icons';
import {SUBSCRIBE_VERIFY} from '../../graphql/mutations';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import {Translate} from '../../components/translations';

import Layout from '../../components/layout/layout';
import {UserVerifyComponentProps} from '../../types';
import pdfPlaybook from '../../images/pdf-playbook.png';
import {setShowModalonClick, setShowModalonExit} from '../../helpers';

const GrowthHackingPlaybook: FC<UserVerifyComponentProps> = ({token}) => {
  const [verifyMutation, {data}] = useMutation(SUBSCRIBE_VERIFY);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    token && token !== 'index.html' && verifyMutation({variables: {verifyCode: token}});
  }, []);

  useEffect(() => {
    setSuccess(false);
    setErrorMessage('');
    if (data && data.WatchSubscribeOps && data.WatchSubscribeOps.verify) {
      const {error, message} = data.WatchSubscribeOps.verify;
      if (error) {
        setErrorMessage(message);
      } else {
        setShowModalonClick('false');
        setShowModalonExit('false');
        setSuccess(true);
      }
    }
  }, [data]);

  const handlePdfDownload = () => {
    window.open('https://hexomatic.com/the_growth_hacking_playbook.pdf', '_blank');
  };

  return (
    <div className="content-wrapper">
      <PageHero title="Thank you, your growth hacking playbook awaits" titleSmall noPaddingBottom />
      <Layout>
        <Section>
          <div className="col-lg-12 text-center">
            {errorMessage ? (
              <div className="alert alert-danger">
                <Translate name={errorMessage} />
              </div>
            ) : success ? (
              <div style={{margin: '0 0 2rem 0'}}>
                <div className="mb-4 download-link">
                  <span onClick={handlePdfDownload}>
                    <FontAwesomeIcon icon={faDownload} className="mr-2 f_size_12" />
                    Download Now
                  </span>
                </div>
                <img
                  onClick={handlePdfDownload}
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    height: 'auto',
                    cursor: 'pointer',
                  }}
                  src={pdfPlaybook}
                  alt="Grouth hacking Playbook"
                />
              </div>
            ) : (
              <FontAwesomeIcon className="mb-5" icon={faSpinner} spin />
            )}
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default GrowthHackingPlaybook;
